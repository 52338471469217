import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Almnd from "../modules/Almnd/almnd";
import Curricular from "../components/Curriculum/curriculum";
import Learning from "../components/Learning/learning";
import Journey from "../components/Journey/journey";
import Booking from "../components/Booking/booking";
import Question from "../components/Question/question";

const Index = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Almnd />} />
        <Route path="/Curricular" element={<Curricular />} />
        <Route path="/Learning" element={<Learning />} />
        <Route path="/Journey" element={<Journey />} />
        <Route path="/Booking" element={<Booking />} />
        <Route path="/Question" element={<Question />} />
      </Routes>
    </Router>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import Curricular from "../../components/Curriculum/curriculum";
import "../style.css";
import Impressivestats from "../../components/ImpressiveStats/Impressivestats";
import Footer from "../../components/Footer/Footer";
import Journey from "../../components/Journey/journey";
import bg from "../../assets/bg.png";
import Question from "../../components/Question/question";
import Learning from "../../components/Learning/learning";
import downArrow from "../../assets/arrowdown.svg";
import Booking from "../../components/Booking/booking";
import ContactUsModal from "../../components/formmodal/ContactModal";
import MainNavbar from "../../components/Navbar/Navbar";
import { Link } from "react-scroll";

const Almnd = () => {
  const [isHomeClicked, setIsHomeClicked] = useState(false);

  function scrollToSection(sectionId) {
    const targetComponent = document.getElementById(sectionId);
    console.log("jdhcxcnzjbh");
    if (targetComponent) {
      console.log("jdhcxcnzjbh33333333333333333");
      targetComponent.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.error("Element not found");
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isHomeClicked]);

  const impressivestats = {
    no_of_stud_label: "Number of Students Enrolled",
    no_of_stud_value: "252+",
    hiring_partners_label: "Placement Partners",
    hiring_partners_value: "10+",
    avg_sal_label: "Average Salary Offered",
    avg_sal_value: "~5L",
  };

  const [ContactUsModalShow, setContactUsModalShow] = useState(false);
  const handleContactUsModalClose = () => {
    setContactUsModalShow(false);
  };
  const handleContactUsModalShow = () => setContactUsModalShow(true);

  return (
    <>
      <div className="dark-background">
        <MainNavbar />
        <Curricular />
        <div className="d-none d-sm-flex flex-column justify-content-center ms-5 ms-xxl-0 pt-5">
          <div>
            <Link
              className="fs_12 color_white text-decoration-none cursor_pointer"
              to="learning"
              smooth={true}
              duration={100}
            >
              Scroll Down
            </Link>
          </div>
          <img className="pt-3" src={downArrow} />
        </div>
        <Impressivestats impressivestats={impressivestats} />
        <Learning />
        <div
          className="d-flex justify-content-center"
          onClick={() => handleContactUsModalShow()}
        >
          <img className="bg" src={bg} />
        </div>
        <Journey />
        <Booking />
        <Question />
        <Footer scrollToSection={scrollToSection} />
      </div>
      <ContactUsModal
        ContactUsModalShow={ContactUsModalShow}
        handleContactUsModalClose={handleContactUsModalClose}
        title={"Contact Us"}
        description={"Unlock Your Potential: Contact Us!"}
      />
    </>
  );
};

export default Almnd;

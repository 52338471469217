import React from "react";
import TimeLine from "../Timeline/timeline";
import "../style.css";

const Journey = () => {
  return (
    <>
      <div className="d-flex justify-content-center m-5">
        <div className="my-div">
          <h1 className="DM_Serif_Text titlepayment text-center text-lg-start">
            The Journey
          </h1>
          <p className="col-lg-5 open_sans d-flex t text-center text-lg-start justify-content-start mt-1 mb-5 color_969696 fs_13">
            Our immersive curriculum, guided by industry experts, empowers you
            to bridge the gap between theory and practice. Through project-based
            learning and real-world challenges, you'll gain the skills and
            confidence needed to excel in your tech career.
          </p>
          <h2 className="DM_Serif_Text titlepayment text-center text-lg-start m-lg-5 p-lg-5">
            Time Line
            <TimeLine />
          </h2>
        </div>
      </div>
    </>
  );
};

export default Journey;

import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import SuccessModal from "./SuccessModal";
import axios from "axios";
import Select, { components, DropdownIndicatorProps } from "react-select";
import SuccessToast from "../SuccessToast";
import ErrorToast from "../ErrorToast";
import "../style.css";
import { toast } from "react-toastify";
import closeIcon from "../../assets/icons/cancel-icon.svg";
import dropDownSvgIcon from "../../assets/arrowdown.svg";

const ContactUsModal = ({
  ContactUsModalShow,
  handleContactUsModalClose,
  title = "",
  description,
  title_color = "",
}) => {
  const phoneRegExp = /^[6,7,8,9][0-9]{0,9}$/;

  const [selectedOption, setSelectedOption] = useState("");
  const [successMessageShow, setSuccessMessageShow] = useState(false);

  const [error, setError] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isGetOtpClicked, setIsGetOtpClicked] = useState(false);

  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(30);

  useEffect(() => {
    let countdownInterval;
    if (isGetOtpClicked && timerSeconds > 0) {
      countdownInterval = setInterval(() => {
        setTimerSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (timerSeconds === 0 && isGetOtpClicked) {
      clearInterval(countdownInterval);
      setIsResendEnabled(true);
    }
    return () => clearInterval(countdownInterval);
  }, [isGetOtpClicked, timerSeconds]);

  const EnrollApi = (values) => {
    axios
      .post("https://api.almnd.in/api/contact-us-create", {
        name: values?.name,
        email_address: values?.email,
        phone_number: values?.phone,
        message: values?.message,
      })
      .then((result) => {
        console.log(result.data);
        // SuccessToast(result.data?.message);
        setSuccessMessageShow(true);

        // handleSuccessMessageShow();
        setError("");
      })
      .catch((error) => {
        console.log(error.response.data.error);
        ErrorToast(error.response.data.message);
        setError(error.response.data.error);
      });
  };

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("!Required"),
      email: Yup.string().required("!Required"),
      phone: Yup.string()
        .required("!Required")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Minimum requires 10 numbers")
        .max(10, "Maximum requires 10 numbers only"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values, "formikvalues");
      EnrollApi(values);
      resetForm();
      handleContactUsModalClose();
      // handleSuccessMessageShow();
      // handleModalClose();
      setError(null);
    },
  });
  const handleModalClose = () => {
    formik.resetForm();
    handleContactUsModalClose();
    // setIsGetOtpClicked(false);
    // setTimerSeconds(30);
    // setIsResendEnabled(false);
    // setIsOtpVerified(false);
  };
  const [isFormValid, setIsFormValid] = useState(false);
  // useEffect(() => {
  //   setIsFormValid(formik.isValid && formik.dirty);
  // }, [formik.isValid, formik.dirty]);

  // const startTimer = () => {
  //   setIsGetOtpClicked(true);
  //   setTimerSeconds(30);
  //   setIsResendEnabled(false);

  //   const countdownInterval = setInterval(() => {
  //     setTimerSeconds((prevSeconds) => prevSeconds - 1);
  //   }, 1000);
  //   setTimeout(() => {
  //     clearInterval(countdownInterval);
  //     setIsResendEnabled(true);
  //   }, 30000);
  // };
  return (
    <>
      <SuccessModal
        successMessageShow={successMessageShow}
        handleSuccessMessageClose={() => setSuccessMessageShow(false)}
        title={"Your Response has been submited successfully "}
      />
      <Modal
        centered
        show={ContactUsModalShow}
        dialogClassName="appproved_form_container"
        contentClassName="border_radius_10px custom-modal-content"
        backdropClassName="create_form_modal_backdrop"
        backdrop={handleModalClose}
        keyboard={true}
        onHide={handleModalClose}
      >
        <Modal.Body className="px-sm-5  mx-sm-3 py-5">
          <div className="d-flex justify-content-between align-items-center mt-1">
            <div className={`titleheading DM_Serif_Text fs_20 ${title_color}`}>
              {title}
            </div>
            <div>
              {" "}
              <img
                src={closeIcon}
                alt=""
                onClick={() => handleModalClose()}
                className="Enroll_Modal_Closeicon"
              />{" "}
            </div>
          </div>
          <div className="Enroll_Modal_Title ">{description}</div>
          <div className="mt-4">
            <form onSubmit={formik.handleSubmit}>
              <div>
                <lable
                  className={`fs_13 fw_600 open_sans ${
                    formik.touched.name && formik.errors.name
                      ? " errorcolor"
                      : ""
                  }`}
                >
                  Name
                </lable>
                <input
                  className={`w-100 inputfields ps-2 ${
                    formik.touched.name && formik.errors.name
                      ? "inputfieldserror"
                      : ""
                  }`}
                  type="text"
                  placeholder="Enter your name"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="errorcolor open_sans ps-2">
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
              <div className="mt-4">
                <lable
                  className={`fs_13 fw_600 open_sans ${
                    formik.touched.email && formik.errors.email
                      ? " errorcolor"
                      : ""
                  }`}
                >
                  Email Address
                </lable>
                <input
                  className={`w-100 inputfields ps-2 ${
                    formik.touched.email && formik.errors.email
                      ? "inputfieldserror"
                      : ""
                  }`}
                  type="email"
                  placeholder="Enter your email address"
                  id="email"
                  email="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="errorcolor open_sans ps-2">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <lable
                  className={`fs_13 fw_600 open_sans ${
                    formik.touched.phone && formik.errors.phone
                      ? " errorcolor"
                      : ""
                  }`}
                >
                  Phone Number
                </lable>
                <div className="position-relative">
                  <input
                    className={`w-100 inputfields ps-2 ${
                      formik.touched.phone && formik.errors.phone
                        ? "inputfieldserror"
                        : ""
                    }`}
                    type="text"
                    maxlength="10"
                    pattern="\d{10}"
                    placeholder="Enter your phone number"
                    id="phone"
                    name="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                </div>
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="errorcolor open_sans ps-2">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>
              <div>{error}</div>
              {title === "Free Workshop" ? (
                <div className="mt-5">
                  <button
                    type="submit"
                    className={`w-100 px-5 p-2 ${
                      !formik.dirty || !formik.isValid
                        ? "submit_inactive_btn"
                        : "submitbtn"
                    }  `}
                    disabled={!formik?.dirty || !formik?.isValid}
                  >
                    SUBMIT
                  </button>
                </div>
              ) : (
                <div className="mt-5">
                  <button
                    type="submit"
                    className={`w-100 px-5 p-2 ${
                      !formik.dirty || !formik.isValid
                        ? "inactivebtn"
                        : "submitbtn"
                    }  `}
                    disabled={!formik?.dirty || !formik?.isValid}
                  >
                    SUBMIT
                  </button>
                </div>
              )}
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactUsModal;

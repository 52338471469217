import React, { useState } from "react";
import "./weekTimeline.css";
import time from "../../assets/time.png";

const TimeLine = () => {
  const [currentWeek, setCurrentWeek] = useState(1);
  const weekList = [
    {
      value: 1,
      title: "Week 1",
      session1: "CONCEPTS OF DESIGN",
      session2: "BASIC DRAWING",
      session3: "LAYOUTS",
      session4: "COLOR & COMPOSITIONS",
      session5: "ADOBE ILLUSTRATOR",
      session6: "PORTFOLIO & INTERNSHIP",
      session7: "ADOBE PHOTOSHOP",
      session8: "ADOBE INDESIGN",
    },
  ];
  return (
    <>
      {weekList
        ?.filter((item) => item?.value === currentWeek)
        .map((data) => {
          return (
            <div className="position-relative ms-lg-5 week-content-container overflow-auto">
              <div className="mt-3">
                <img
                  className="d-none d-md-block w-100 weekimg"
                  height="500px"
                  src={time}
                />
                <img
                  className="d-block d-sm-none w-100 weekimg"
                  height="350px"
                  src={time}
                />
                <img
                  className="d-none d-sm-block d-md-none w-100 weekimg"
                  height="400px"
                  src={time}
                />
              </div>

              {
                <div className="text-center">
                  {data.session1 && (
                    <div className="session_1 fs_sm_10 fs_14 open_sans px-3 fw_600">
                      {data.session1}
                    </div>
                  )}
                  {data?.session2 && (
                    <div className="session_2 fs_sm_10 fs_14 open_sans px-3 fw_600">
                      {data.session2}
                    </div>
                  )}
                  {data?.session3 && (
                    <div className="session_3 fs_sm_10 fs_14 open_sans px-3 fw_600 me-3 me-sm-0">
                      {data.session3}
                    </div>
                  )}
                  {data.session4 && (
                    <div className="session_4 fs_sm_10 fs_14 open_sans px-3 fw_600 me-1 me-sm-0">
                      {data.session4}
                    </div>
                  )}
                  {data.session5 && (
                    <div className="session_5 fs_sm_10 fs_14 open_sans px-3 fw_400 me-1 me-sm-0">
                      {data.session5}
                    </div>
                  )}
                  {data.session6 && (
                    <div className="session_6 fs_sm_10 fs_14 open_sans px-3 fw_400 me-1 me-sm-0">
                      {data.session6}
                    </div>
                  )}
                  {data.session7 && (
                    <div className="session_7 fs_sm_10 fs_14 open_sans px-3 fw_400 me-1 me-sm-0">
                      {data.session7}
                    </div>
                  )}
                  {data.session8 && (
                    <div className="session_8 fs_sm_10 fs_14 open_sans px-3 fw_400 me-1 me-sm-0">
                      {data.session8}
                    </div>
                  )}
                </div>
              }
            </div>
          );
        })}
    </>
  );
};

export default TimeLine;

import React, { useEffect, useState } from "react";
import "../style.css";
import EnrollNowModal from "../formmodal/EnrollNowModal";
import { Link } from "react-scroll";

const MainNavbar = () => {
  const [EnrollModalShow, setEnrollModalShow] = useState(false);
  const handleEnrollModalClose = () => {
    setEnrollModalShow(false);
  };
  const handleEnrollModalShow = () => setEnrollModalShow(true);

  return (
    <>
      <div className="navbar d-flex justify-content-md-end justify-content-center position-fixed">
        <div className="d-flex align-items-center w-100">
          <div className="d-none d-md-flex flex-md-fill justify-content-end">
            <div className="navbar-links d-flex me-3 position-relative border-md-end border-md-bottom">
              <Link
                className="navbar-link1 fs_20 cursor_pointer"
                to="Question"
                smooth={true}
                duration={100}
              >
                CONTACT US
              </Link>
              <div className="navbar-divider py-2"></div>{" "}
              <a
                className="navbar-link fs_20 cursor_pointer"
                onClick={() => {
                  handleEnrollModalShow();
                }}
              >
                APPLY NOW!
              </a>
            </div>
          </div>
          <div className="d-md-none w-100">
            <div className="navbar-links d-flex justify-content-center border border-3">
              <Link
                className="navbar-link1 fs_sm_12 cursor_pointer w-100"
                to="Question"
                smooth={true}
                duration={100}
              >
                CONTACT US
              </Link>
              <div className="navbar-divider py-2"></div>{" "}
              <a
                href="#"
                className="navbar-link fs_sm_12 cursor_pointer w-100"
                onClick={() => {
                  handleEnrollModalShow();
                }}
              >
                APPLY NOW!
              </a>
            </div>
          </div>
        </div>
      </div>
      <EnrollNowModal
        EnrollModalShow={EnrollModalShow}
        handleEnrollModalClose={handleEnrollModalClose}
        title={"Apply Now"}
        description={"Unlock Your Potential: Apply Now!"}
      />
    </>
  );
};

export default MainNavbar;

import React from "react";
import "../style.css";
import quotes from "../../assets/“.svg";
import "../Curriculum/curriculum.css";
const Impressivestats = ({ impressivestats }) => {
  return (
    <>
      <div className="d-none d-sm-block mt-5 pt-4">
        <div className="row g-0 d-flex justify-content-center background mt-5 pt-3 g-0 py-5 mask_bg">
          <div className="col-lg-6 col-12 hiredstudents">
            <div className="mt-5 ps-sm-5 d-none d-sm-block">
              <div className="d-flex justify-content-center gap-lg-4 me-5 pe-5 me-lg-0 pe-lg-0">
                <span>
                  <img className="quotes" width="50px" src={quotes} />
                </span>
                <h3 className="DM_Serif_Text impressive mt-md-5 fs_50 mt-5">
                  Here’s A Little About <br />
                  Our Achievements.
                </h3>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-12 hiredstudents">
            <div className="d-flex justify-content-center row g-4 gy-xl-2 gy-lg-5 justify-content-around justify-content-sm-center align-items-center col-12 col-xl-11 col-xxl-10">
              <div className=" d-flex justify-content-center">
                <div className="d-lg-flex gap-5 ms-4">
                  <div className="row justify-content-center g-0 gx-0 mt-4 text-nowrap">
                    <div className="  col-sm-4 col-12 mt-3 ps-3">
                      <div className=" d-flex justify-content-center me-5">
                        <div className=" d-flex gap-2 text-center justify-content-center">
                          <div className="colorCard align-content-center">
                            <div className="DM_Serif_Text hirings">
                              {impressivestats?.no_of_stud_value}
                            </div>
                            <div className="open_sans partnerss">
                              Number of
                              <br />
                              Students Enrolled
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="  col-sm-4 col-12 mt-3 ps-3">
                      <div className=" d-flex justify-content-center me-3">
                        <div className=" d-flex gap-2 text-center justify-content-center">
                          <div className="colorCards p-lg-5 align-content-center">
                            <div className="DM_Serif_Text hirings">
                              {impressivestats?.hiring_partners_value}
                            </div>
                            <div className="open_sans partnerss">
                              Placement
                              <br />
                              Partners
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="  col-sm-4 col-12 mt-3 ps-3">
                      <div className=" d-flex justify-content-center">
                        <div className=" d-flex gap-2 text-center justify-content-center">
                          <div className=" colorCardss align-items-center">
                            <div className="DM_Serif_Text hiringss ">
                              {impressivestats?.avg_sal_value}
                            </div>
                            <div className="open_sans partners ">
                              Average
                              <br />
                              Salary Offered
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-block d-sm-none background mask_bg">
        <div className="row mx-3  py-5">
          <div className="d-flex justify-content-center mb-4 pe-5 ">
            <span className="">
              <img className="" src={quotes} width={40} alt="quotes-icon" />
            </span>
           <h2>
           <div className="text-white fs_40 DM_Serif_Text mt-4">
              Impressive Stats
            </div>
           </h2>
          </div>
          <div className="col-6 text-white">
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex justify-content-center align-items-center students_hired w-100 border_radus_7px">
                <div>
                  <div className="text-center fs_34 DM_Serif_Text letter_space fw_700">
                    {impressivestats?.no_of_stud_value}
                  </div>
                  <div className="fs_16 fw_700 open_sans">
                    Number of
                    <br />
                    Students Enrolled
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 text-white">
            <div className="d-flex justify-content-start align-items-center">
              <div className="d-flex justify-content-center align-items-center hiring_partners w-100 border_radus_7px">
                <div>
                  <div className="text-center fs_34 DM_Serif_Text letter_space fw_700">
                    {impressivestats?.hiring_partners_value}
                  </div>
                  <div className="fs_16 fw_700 open_sans">
                    Placement
                    <br />
                    Partners
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4 text-white">
            <div className="d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-center align-items-center average_salary w-100 border_radus_7px">
                <div>
                  <div className="color_black fs_34 DM_Serif_Text letter_space fw_700 text-center">
                    {impressivestats?.avg_sal_value}
                  </div>
                  <div className="color_black fs_16 fw_700 open_sans">
                    Average
                    <br />
                    Salary Offered
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Impressivestats;

import React, { useEffect, useState } from "react";
import "../style.css";
import Curriculum from "../../assets/curriculum.png";
import vector from "../../assets/Vector.png";
import arrow from "../../assets/undraw_balloon.png";
import "./curriculum.css";
import FormModal from "../formmodal/FormModal";
import EnrollNowModal from "../formmodal/EnrollNowModal";

const Curricular = () => {
  const [formModalShow, setFormModalShow] = useState(false);
  const handleFormModalClose = () => {
    setFormModalShow(false);
  };
  const handleFormModalShow = () => setFormModalShow(true);

  const [EnrollModalShow, setEnrollModalShow] = useState(false);
  const [activeTab, setActiveTab] = useState("home");
  const handleEnrollModalClose = () => {
    setEnrollModalShow(false);
  };
  const handleEnrollModalShow = () => setEnrollModalShow(true);
  return (
    <>
      <div className="mt-5">
        <div className="col-md-11 pb-5 pb-sm-0">
          <div className="d-lg-flex justify-content-center align-items-center">
            <div className="vertical-text d-none d-lg-block">
              <span className="p-5 fs_14 vertical-span">MYSORE</span>
              <span className="fs_28">ChidiyaGhar X almnd</span>
            </div>
            <div className="col-lg-6 col-12 d-lg-block">
              <div className="d-flex justify-content-end ms-5 ms-xxl-0">
                <img className="w-75 d-none d-xxl-block" src={Curriculum} />
                <img
                  className="w-100 d-xxl-none"
                  style={{ maxWidth: "90rem" }}
                  src={Curriculum}
                />
              </div>
            </div>
            <div className="col-lg-6 col-12 d-flex justify-content-center align-items-center">
              <div className="mt-5 px-lg-5 mx-5 ms-lg-5 me-lg-0">
              <div className="d-flex text-align-center align-items-center gap-2 ms-lg-5 ps-lg-5 ">
                    <div>
                      <img style={{ width: "15px" }} src={vector} />
                    </div>
                    <div className="open_sans commenticon fs_15 fs_sm_12 ">
                      This is a step up, which sets you a League Apart.
                    </div>
                  </div>
                <h1>
                  <div
                    className="fs_lg_28 fs_36 letter_spacing fw_600 DM_Serif_Text mt-3 ms-lg-5 ps-lg-5 line_height_4rem text-start"
                    style={{ maxWidth: "400px", color: "white" }}
                  >
                    Paint The World Around You!{" "}
                    <span className="commenticon">Become</span> a Graphic
                    Designer...
                  </div>
                </h1>
                <h2>
                  <div className="embark Jost mt-4 ms-lg-5 ps-lg-5 text-start">
                    Now, make a career by doing what you love. Learn to design
                    from leading industry practitioners. Get a chance to work on
                    live projects while you learn and have a portfolio ready by
                    the time you complete your course and internship.
                  </div>
                </h2>
                <div className="d-flex gap-5 mt-4 ms-lg-5 ps-lg-5">
                  <div>
                    <button
                      className="enrollblubtn fs_19 fs_sm_15 px-lg-4 px-2  py-3 "
                      onClick={() => handleEnrollModalShow()}
                    >
                      APPLY NOW
                    </button>
                  </div>
                  <div>
                    <button
                      className="curriculumbtn"
                      onClick={() => handleFormModalShow()}
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <img
                          style={{ width: "20px", height: "40px" }}
                          src={arrow}
                        />
                        <div className="text-start fs_16 fs_sm_14">
                          VIEW <br /> CURRICULUM
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormModal
        formModalShow={formModalShow}
        handleFormModalClose={handleFormModalClose}
        title={"Curriculum"}
        description={"Unlock Your Potential: Download Now!"}
        selectedCourse="graphic_design"
      />
      <EnrollNowModal
        EnrollModalShow={EnrollModalShow}
        handleEnrollModalClose={handleEnrollModalClose}
        title={"Apply Now"}
        description={"Unlock Your Potential: Apply Now!"}
        selectedCourse="graphic_design"
      />
    </>
  );
};

export default Curricular;

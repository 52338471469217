import { ToastContainer } from 'react-toastify';
import './App.css';
import Index from './routes';
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
       <Index />
       <ToastContainer />
    </div>
  );
}

export default App;

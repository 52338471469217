import React, { useState } from "react";
import "../style.css";
import ContactUsModal from "../formmodal/ContactModal";

const Question = () => {
  const [ContactUsModalShow, setContactUsModalShow] = useState(false);
  const handleContactUsModalClose = () => {
    setContactUsModalShow(false);
  };
  const handleContactUsModalShow = () => setContactUsModalShow(true);
  return (
    <>
      <div id="Question" className="d-flex-column justify-content-center mt-5">
        <div className="DM_Serif_Text titlepayment text-center">
          Got Questions?
        </div>
        <div className="open_sans d-flex  text-center justify-content-center mt-3 color_969696 fs_sm_15 fs_16">
          We would love to answer them all!
          <br /> Give us a call or request a callback.
        </div>
        <div className="d-flex-column justify-content-center align-items-center mt-5 mb-5">
          <button
            className="connectbtn py-2 px-5 open_sans"
            onClick={() => handleContactUsModalShow()}
          >
            REQUEST CALLBACK
          </button>

          <button
            className="connectbtn py-2 px-5 open_sans"
            onClick={() => {
              const phoneNumber = "+917892825369";
              window.location.href = `tel:${phoneNumber}`;
            }}
          >
            CALL: +91 789 2825 369
          </button>

          <button
            className="connectbtn py-2 px-5 open_sans"
            onClick={() =>
              window.open("https://maps.app.goo.gl/1H8Pb6uLskCDudhs7", "_blank")
            }
          >
            {"VISIT US > GMAPS"}
          </button>
        </div>
      </div>
      <ContactUsModal
        ContactUsModalShow={ContactUsModalShow}
        handleContactUsModalClose={handleContactUsModalClose}
        title={"Contact Us"}
        description={"Unlock Your Potential: Contact Us!"}
      />
    </>
  );
};

export default Question;

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SuccessToast = (message) => {
    return toast.success(message, {
        position:"bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "fs_16"
    });
};

export default SuccessToast;

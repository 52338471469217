import React from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import "../style.css";

const SuccessModal = ({
    successMessageShow,
    handleSuccessMessageClose,
    title = ``,
    description,
    subsection = false,
    title_color = "", }) => {
    return (
        <>
            <Modal
                centered
                show={successMessageShow}
                onHide={handleSuccessMessageClose}
                dialogClassName="appproved_successfully_container mx-auto px-3"
                contentClassName="border_radius_10px"
                backdropClassName="create_password_modal_backdrop"
                backdrop={"static"}
                keyboard={false}
            >
                <Modal.Body>
                    <div className="d-flex justify-content-center mt-3">
                        <span className={`open_sans titleheadings ${title_color} text-center`}>{title}</span>
                    </div>
                    {/* {subsection ? (
                        <div className="d-flex justify-content-center mt-3">
                            <span className="text-center fw_500 fs_16 spanish_gray_color">
                                {description}
                            </span>
                        </div>
                    ) : null} */}

                    <div className="d-flex justify-content-center mt-5 mb-4">
                        <button className='okaybtn px-5 py-2' onClick={() => handleSuccessMessageClose()}>Okay</button>
                        {/* <Okaybtn okayFn={handleSuccessMessageClose} /> */}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SuccessModal
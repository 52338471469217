import React, { useState } from "react";
import ContactUsModal from "../formmodal/ContactModal";

const Learning = () => {
  const [ContactUsModalShow, setContactUsModalShow] = useState(false);
  const handleContactUsModalClose = () => {
    setContactUsModalShow(false);
  };
  const handleContactUsModalShow = () => setContactUsModalShow(true);

  return (
    <>
      <div id="learning" className="mt-md-5 py-5">
        <div className="mt-md-5 pt-md-5 d-flex justify-content-center">
          <div className="col-lg-4 col-11">
            <h1 className="titlepayment d-flex justify-content-center mt-5 DM_Serif_Text text-center">
              Things To Know Before Signing Up For The Journey
            </h1>
            <div className=" d-flex justify-content-center align-content-center text-center ">
              <p className="internship mt-3 open_sans">
                We focus on providing you with hands-on industry experience by
                engaging you on live projects, tight deadlines, & assignments,
                while cultivating an atmosphere for growth and crazy fun!
              </p>
            </div>
            <div className="p-5">
              <div className="eligibility px-5">
                <div className="open_sans text-start eligibilities">
                  Eligibility
                </div>
                <div className="DM_Serif_Text text-start eliggraduate">
                  Anyone older than 16 years
                </div>
              </div>

              <div className=" d-flex justify-content-start align-items-start mt-4">
                <div className="duration px-5">
                  <div className="open_sans durations text-start">Duration</div>
                  <div className="DM_Serif_Text durationss text-start">
                    8 Weeks | 40 Sessions + Internship
                  </div>
                </div>
              </div>
              <div className=" d-flex justify-content-start align-items-center mt-4">
                <div
                  className="eligibg px-5 d-flex justify-content-between cursor_pointer"
                  style={{ transform: "rotate(-4deg)" }}
                  onClick={() => handleContactUsModalShow()}
                >
                  <div>
                    <div className="DM_Serif_Text durations text-start">
                      Apply Now
                    </div>
                    <div className="DM_Serif_Text durationss text-start">
                      Become A Graphic Designer
                    </div>
                  </div>
                  <div className="DM_Serif_Text pt-3 fs_30 color_white">
                    {">"}
                  </div>
                </div>
              </div>
              <div className=" d-flex mt-4">
                <div className="duration justify-content-end px-5">
                  <div className="DM_Serif_Text durations text-start">
                    Covered in the Course
                  </div>
                  <div className="open_sans durationss mt-4 ps-3 d-flex gap-2">
                    <span className="dots"></span>
                    <span className="DM_Serif_Text text-start">
                      Colour Theory & Compositions
                    </span>
                  </div>
                  <div className="open_sans durationss ps-3 d-flex gap-2">
                    <span className="dots"></span>
                    <span className="DM_Serif_Text text-start">
                      Concepts of Design & Layouts
                    </span>
                  </div>
                  <div className="open_sans durationss ps-3 d-flex gap-2">
                    <span className="dots"></span>
                    <span className="DM_Serif_Text text-start">
                      Industry Standard Softwares
                    </span>
                  </div>
                  <div className="open_sans durationss ps-3 d-flex gap-2">
                    <span className="dots"></span>
                    <span className="DM_Serif_Text text-start">
                      Portfolio + 1 month Internship
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactUsModal
        ContactUsModalShow={ContactUsModalShow}
        handleContactUsModalClose={handleContactUsModalClose}
        title={"Contact Us"}
        description={"Unlock Your Potential: Contact Us!"}
      />
    </>
  );
};

export default Learning;

import "./booking.css";
import React from "react";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../style.css";
import axios from "axios";
import SuccessToast from "../SuccessToast";
import ErrorToast from "../ErrorToast";
import arrow from "../../assets/undraw_balloon.png";
import FormModal from "../formmodal/FormModal";
import EnrollNowModal from "../formmodal/EnrollNowModal";
import ContactUsModal from "../formmodal/ContactModal";

const Booking = () => {
  const [formModalShow, setFormModalShow] = useState(false);
  const handleFormModalClose = () => {
    setFormModalShow(false);
  };
  const handleFormModalShow = () => setFormModalShow(true);

  const [EnrollModalShow, setEnrollModalShow] = useState(false);
  const handleEnrollModalClose = () => {
    setEnrollModalShow(false);
  };
  const handleEnrollModalShow = () => setEnrollModalShow(true);
  const [ContactUsModalShow, setContactUsModalShow] = useState(false);
  const handleContactUsModalClose = () => {
    setContactUsModalShow(false);
  };
  const handleContactUsModalShow = () => setContactUsModalShow(true);
  return (
    <div className="justify-content-center d-flex">
      <div className="bg-light booking-container w-lg-75">
        <div className="d-flex justify-content-between bg-ligth">
          <div className="col-12 p-5">
            <div className=" d-flex justify-content-md-center ">
              <div className=" d-md-flex justify-content-md-center gap-sm-0 gap-md-5 gap-lg-0">
                <div className="col-md-6 col-12">
                  <div className="mt-5 p-5 d-flex justify-content-xl-start">
                    <div className="col-md-10 col-12 text-start">
                      <h6 className="DM_Serif_Text col-lg-8 color_black fs_50 fw_600 me-lg-5 pe-lg-5">
                        Start with a Free Trial.
                      </h6>
                      <label className="col-10 fw_400 bookText">
                        Worried about fitting in? <br />
                        Not sure if you want to pursue a career in this field?
                      </label>
                      <button
                        className="bookbtn fs_17 px-4 py-2 mt-5 "
                        onClick={() => handleContactUsModalShow()}
                      >
                        BOOK A TRIAL CLASS
                      </button>
                      <label className="col-10 fw_400 bookText mt-5">
                        Or would you like to speak to our counsellor?
                      </label>
                      <div className="d-flex gap-5 mt-3">
                        <div>
                          <button
                            className="bookbtn fs_17 fs_sm_14 px-4 py-3 "
                            onClick={() => handleContactUsModalShow()}
                          >
                            REQUEST CALLBACK
                          </button>
                        </div>
                        <div>
                          <button
                            className="contactbtn fs_14"
                            onClick={() => {}}
                          >
                            <div className="d-flex align-items-center">
                              <div className="text-start">
                                COUNSELLOR:
                                <br />
                                +91 789 2825 369
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 dark-background text-start mt-1">
                  <div className="p-5">
                    <h6 className="DM_Serif_Text col-lg-5 color_white fs_50 fw_600 me-lg-5 pe-lg-5">
                      Book My Seat!
                    </h6>
                    <label className="col-10 fw_400 bookText2">
                      Here’s my MONEY!
                      <br /> I want to be a graphic designer and make a career
                      in this field.
                    </label>
                    <label className="col-10 fw_400 color_white fs_24 pt-5">
                      FEE STRUCTURE
                    </label>
                    <label className="col-10 fw_400 bookText2 pt-3">
                      There are 2 ways to pay:
                    </label>
                    <div className=" d-flex justify-content-between align-items-center mt-3">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6 pb-5">
                            <div>
                              <span className="fw_400 bookText3">
                                One Time Payment
                              </span>
                              <div className="open_sans listitems mt-1 ps-3 d-flex gap-2">
                                <span className="bookText2">
                                  Course Fee:
                                  <span className="bookText3">
                                    {" "}
                                    Rs 25,000/-
                                  </span>
                                </span>
                              </div>
                              <div className="open_sans listitems ps-3 d-flex gap-2">
                                <span className="pt-1">
                                  <span className="bookText2">Discount: </span>{" "}
                                  <span className="bookText3">Rs 2,500/-</span>
                                </span>
                              </div>
                              <div className="open_sans listitems ps-3 d-flex gap-2">
                                <span className="pt-1">
                                  <span className="bookText2">GST: </span>{" "}
                                  <span className="bookText3"> Rs 4,500/-</span>
                                </span>
                              </div>
                              <div className="open_sans listitems ps-3 d-flex gap-2">
                                <span className="pt-1">
                                  <span className="bookText2">TOTAL: </span>{" "}
                                  <span className="bookText3">Rs 29,500/</span>
                                </span>
                              </div>
                              <div className="open_sans listitems ps-3 d-flex gap-2">
                                <span className="pt-1">
                                  <span className="bookText2">
                                    One Time Payment:{" "}
                                  </span>{" "}
                                  <span className="bookText3">
                                    {" "}
                                    Rs 27,000/-
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 pb-5">
                            <div>
                              <span className="fw_400 bookText3">
                                Monthly EMI - 2 Months
                              </span>
                              <div className="open_sans listitems mt-1 ps-3 d-flex gap-2">
                                <span className="bookText2">
                                  Course Fee:
                                  <span className="bookText3">
                                    {" "}
                                    Rs 25,000/-
                                  </span>
                                </span>
                              </div>
                              <div className="open_sans listitems ps-3 d-flex gap-2">
                                <span className="pt-1">
                                  <span className="bookText2">Discount: </span>{" "}
                                  <span className="bookText3">Rs 2,500/-</span>
                                </span>
                              </div>
                              <div className="open_sans listitems ps-3 d-flex gap-2">
                                <span className="pt-1">
                                  <span className="bookText2">GST: </span>{" "}
                                  <span className="bookText3"> Rs 4,500/-</span>
                                </span>
                              </div>
                              <div className="open_sans listitems ps-3 d-flex gap-2">
                                <span className="pt-1">
                                  <span className="bookText2">TOTAL: </span>{" "}
                                  <span className="bookText3">Rs 29,500/</span>
                                </span>
                              </div>
                              <div className="open_sans listitems ps-3 d-flex gap-2">
                                <span className="pt-1">
                                  <span className="bookText2">
                                    Monthly EMI:{" "}
                                  </span>{" "}
                                  <span className="bookText3">
                                    {" "}
                                    Rs 14,750/-
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-5 mt-4 ">
                      <div>
                        <button
                          className="enrollblubtn2 fs_13 fs_sm_11 px-lg-5 px-2 py-3"
                          onClick={() => handleEnrollModalShow()}
                        >
                          APPLY NOW!
                        </button>
                      </div>
                      <div>
                        <button
                          className="curriculumbtn2"
                          onClick={() => handleFormModalShow()}
                        >
                          <div className="d-flex gap-3 align-items-center">
                            <img
                              style={{ width: "14px", height: "28px" }}
                              src={arrow}
                            />
                            <div className="text-start">
                              VIEW <br /> CURRICULUM
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                    <label className="align-item-end pt-5 mt-5 fw_400 bookText2 text-end">
                      The course is conducted at the discretion of the
                      institute. The institute holds the power to change/alter
                      the course at its will, with no prior intimation.
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormModal
        formModalShow={formModalShow}
        handleFormModalClose={handleFormModalClose}
        title={"Curriculum"}
        description={"Unlock Your Potential: Download Now!"}
        selectedCourse="graphic_design"
      />
      <EnrollNowModal
        EnrollModalShow={EnrollModalShow}
        handleEnrollModalClose={handleEnrollModalClose}
        title={"Apply Now"}
        description={"Unlock Your Potential: Apply Now!"}
        selectedCourse="graphic_design"
      />
      <ContactUsModal
        ContactUsModalShow={ContactUsModalShow}
        handleContactUsModalClose={handleContactUsModalClose}
        title={"Contact Us"}
        description={"Unlock Your Potential: Contact Us!"}
      />
    </div>
  );
};

export default Booking;

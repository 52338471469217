import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import SuccessModal from "./SuccessModal";
import axios from "axios";
import SuccessToast from "../SuccessToast";
import ErrorToast from "../ErrorToast";
import closeIcon from "../../assets/icons/cancel-icon.svg";

// import PDF from "../../assets/Syllabus (10).pdf";

const FormModal = ({
  formModalShow,
  handleFormModalClose,
  title = "",
  description,
  subsection = false,
  title_color = "",
  selectedCourse = "full_stack",
}) => {
  const [error, setError] = useState("");

  const PDF = "https://s3.ap-south-1.amazonaws.com/zs2.0/Syllabus+(3).pdf";
  const FPDF =
    "https://s3.ap-south-1.amazonaws.com/zs2.0/Documents/9020e19e-71b2-4061-98db-85614ce73382.pdf";
  const BPDF =
    "https://s3.ap-south-1.amazonaws.com/zs2.0/Documents/3bd46756-516a-4162-b250-8da262d10c91.pdf";
  const MPDF =
    "https://s3.ap-south-1.amazonaws.com/zs2.0/Documents/4147c6a7-a825-4b87-b584-c032ceef4108.pdf";

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const CurriculumApi = (values) => {
    axios
      .post("https://api.almnd.in/api/curriculum-create", {
        name: values?.name,
        email_address: values?.email,
        phone_number: values?.phone,
      })
      .then((result) => {
        console.log(result.data, "jcghgcghchg");
        // SuccessToast(result.data);
        // handleSuccessMessageShow();
        SuccessToast("Downloaded Successfully");
        setError("");
        if (selectedCourse === "full_stack") {
          downloadPDF();
        } else if (selectedCourse === "front_end") {
          frontEndDownloadPdf();
        } else if (selectedCourse === "back_end") {
          backEndDownloadPdf();
        } else if (selectedCourse === "mobile") {
          mobileDownloadPdf();
        } else {
          downloadPDF();
        }
      })
      .catch((error) => {
        console.log(error.response.data.error);
        ErrorToast(error.response.data.error);
        setError(error.response.data.error);
      });
  };

  const downloadPDF = () => {
    const link = document.createElement("a");
    link.href = PDF;
    link.target = "_blank";
    link.download = "Syllabus.pdf";
    link.click();
  };
  const frontEndDownloadPdf = () => {
    const link = document.createElement("a");
    link.href = FPDF;
    // link.target = "_blank";
    link.download = "9020e19e-71b2-4061-98db-85614ce73382.pdf";
    link.click();
  };
  const backEndDownloadPdf = () => {
    const link = document.createElement("a");
    link.href = BPDF;
    // link.target = "_blank";
    link.download = "3bd46756-516a-4162-b250-8da262d10c91.pdf";
    link.click();
  };
  const mobileDownloadPdf = () => {
    const link = document.createElement("a");
    link.href = MPDF;
    // link.target = "_blank";
    link.download = "4147c6a7-a825-4b87-b584-c032ceef4108.pdf";
    link.click();
  };
  const workshopApi = (values) => {
    axios
      .post("https://api.almnd.in/api/attend-workshop-create", {
        name: values?.name,
        email_address: values?.email,
        phone_number: values?.phone,
      })
      .then((result) => {
        console.log(result.data, "jhvhjchchjch");
        SuccessToast(result?.data?.message, "jgcghcjgcgkyc");
        // handleSuccessMessageShow();
        setError("");
      })
      .catch((error) => {
        console.log(error.response.data.error);
        ErrorToast(error.response.data.message);
        setError(error.response.data.error);
      });
  };

  // const [successMessageShow, setSuccessMessageShow] = useState(false);
  // const handleSuccessMessageClose = () => {
  //     setSuccessMessageShow(false);
  // };
  // const handleSuccessMessageShow = () => setSuccessMessageShow(true);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("!Required"),
      email: Yup.string().required("!Required"),
      phone: Yup.string()
        .required("!Required")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Minimum requires 10 numbers")
        .max(10, "Maximum requires 10 numbers only"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values, "formikvalues");
      {
        title === "Curriculum"
          ? CurriculumApi(values)
          : title === "Workshop"
          ? workshopApi(values)
          : CurriculumApi(values);
      }
      resetForm();
      handleFormModalClose();
      // handleSuccessMessageShow();
      setError(null);
    },
  });
  const handleModalClose = () => {
    formik.resetForm();
    handleFormModalClose();
  };
  // console.log(formik?.dirty, formik?.isValid, "isValid");
  return (
    <>
      {/* <SuccessModal
                successMessageShow={successMessageShow}
                handleSuccessMessageClose={handleSuccessMessageClose}
                title={`${title === "Curriculum" ? "Syllabus downloaded successfully" : "Joined to workshop successfully"}`}
            /> */}
      <Modal
        centered
        show={formModalShow}
        onHide={handleModalClose}
        dialogClassName="appproved_form_container"
        contentClassName="border_radius_10px"
        backdropClassName="create_form_modal_backdrop"
        backdrop={"false"}
        keyboard={false}
      >
        <Modal.Body className="px-5 mx-3 py-5">
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div className={`open_sans titleheading fs_20 ${title_color}`}>
              {title}
            </div>
            <div>
              {" "}
              <img
                src={closeIcon}
                alt=""
                onClick={() => handleModalClose()}
                className="Enroll_Modal_Closeicon"
              />{" "}
            </div>
          </div>
          <div className="Enroll_Modal_Title ">{description}</div>
          <div className="mt-5">
            <form onSubmit={formik.handleSubmit}>
              <div>
                <lable
                  className={`labels open_sans ${
                    formik.touched.name && formik.errors.name
                      ? " errorcolor"
                      : ""
                  }`}
                >
                  Name
                </lable>
                <input
                  className={`w-100 inputfields p-3 ps-2 ${
                    formik.touched.name && formik.errors.name
                      ? "inputfieldserror"
                      : ""
                  }`}
                  type="text"
                  placeholder="Enter your name"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="errorcolor open_sans ps-2">
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <lable
                  className={`labels open_sans ${
                    formik.touched.email && formik.errors.email
                      ? " errorcolor"
                      : ""
                  }`}
                >
                  Email Address
                </lable>
                <input
                  className={`w-100 inputfields p-3 ps-2 ${
                    formik.touched.email && formik.errors.email
                      ? "inputfieldserror"
                      : ""
                  }`}
                  type="email"
                  placeholder="Enter your email address"
                  id="email"
                  email="email"
                  onChange={formik.handleChange}
                  // onChange={(e) => {
                  //     setEmail(e.target.value);
                  // }}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  // value={email}
                  // onChange={handleEmail}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="errorcolor open_sans ps-2">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <lable
                  className={`labels open_sans ${
                    formik.touched.phone && formik.errors.phone
                      ? " errorcolor"
                      : ""
                  }`}
                >
                  Phone Number
                </lable>
                <input
                  className={`w-100 inputfields p-3 ps-2 ${
                    formik.touched.phone && formik.errors.phone
                      ? "inputfieldserror"
                      : ""
                  }`}
                  type="text"
                  placeholder="Enter your phone number"
                  maxlength="10"
                  pattern="\d{10}"
                  id="phone"
                  name="phone"
                  onChange={formik.handleChange}
                  // onChange={(e) => {
                  //     setPhone(e.target.value);
                  // }}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  // value={phone}
                  // onChange={handlePhone}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="errorcolor open_sans ps-2">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>
              <div>{error}</div>
              <div className="mt-5">
                {title == "Enter Details to Download" ? (
                  <button
                    type="submit"
                    className={`w-100 px-5 p-3 ${
                      !formik.dirty || !formik.isValid
                        ? "inactivebtn"
                        : "submitbtn"
                    }`}
                    disabled={!formik?.isValid}
                  >
                    Download Syllabus
                  </button>
                ) : title === "Curriculum" ? 
                
                  <button
                    type="submit"
                    className={`w-100 px-5 p-3 fs_16 ${
                      !formik.dirty || !formik.isValid
                        ? "inactivebtn"
                        : "submitbtn"
                    }`}
                    disabled={!formik.dirty || !formik.isValid}
                  >
                    Download Syllabus
                  </button>
                : (
                  <button
                    type="submit"
                    className={`w-100 px-5 p-3 fs_16 ${
                      !formik.dirty || !formik.isValid
                        ? "inactivebtn"
                        : "submitbtn"
                    }`}
                    disabled={!formik.dirty || !formik.isValid}
                  >
                    Join workshop
                  </button>
                )}
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FormModal;
